export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAPek-iI5Z-K0datX3kgntyh0rqG_3lOcc",
    authDomain: "evi-e-2b651.firebaseapp.com",
    databaseURL: "https://evi-e-2b651-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "evi-e-2b651",
    storageBucket: "evi-e-2b651.appspot.com",
    messagingSenderId: "288272682998",
    appId: "1:288272682998:web:2011983a63d88d420281d7",
    measurementId: "G-ZCX4HFRRRH"
  },
  googleApiKey: 'AIzaSyAIIYOxA7qeetFz6TuR1Qewc0Rrjhzx7ZU',
};