import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../_services/auth.service';
import { AlertService } from '../../_services/alert.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.css']
})
export class RecoverPasswordComponent {
  resetForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    public authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.resetForm = this.fb.group({
      emailfield: ['', Validators.required]
    });
  }

  onClick(): void {
    const inputValue = this.resetForm.get('emailfield').value;
    console.log('Input Value:', inputValue);

    // Add your custom functionality with the input value here
    this.authService.sendPasswordResetEmail(inputValue).then(
      res => {
        Swal.fire('Je wachtwoord is gereset.', 'je ontvangt een email met instructies om je wachtwoord te resetten.', 'success')
       
      },
      err => {
        console.log(err.message);
        if(err.message == "Firebase: The email address is badly formatted. (auth/invalid-email)."){
          Swal.fire('Oeps, er ging iets mis!', 'Het emailadress is niet correct ingevuld.', 'error')
        }else if(err.message =="Firebase: There is no user record corresponding to this identifier. The user may have been deleted. (auth/user-not-found)."){
          Swal.fire('Oeps, er ging iets mis!', 'Geen gebruiker gevonden met dit emailadres.', 'error')
        }else{
          Swal.fire('Oeps, er ging iets mis!', err.message, 'error')
        }
      }
    );
  }

}
