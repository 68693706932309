<footer id="footer" class="footer footer-static footer-light navbar-border navbar-shadow" *ngIf="showFooter">
  <p class="clearfix blue-grey lighten-2 text-sm-center mb-0 px-2"><span
      class="float-md-left d-block d-md-inline-block">Copyright &copy; 2024 <a [routerLink]=""
        class="text-bold-800 grey darken-2" href="https://evi-e.nl"
        target="_blank">EVI-E </a></span><span *ngIf="!hideMadeWithLove"
      class="float-md-right d-block d-md-inline-block d-none d-lg-block">Hand-crafted & Made with <i
        class="feather ft-heart pink"></i>
      <span id="scroll-top"></span></span></p>
</footer>

<footer id="footer" class="footer fixed-bottom footer-light navbar-border navbar-shadow" *ngIf="fixedFooter">
  <p class="clearfix blue-grey lighten-2 text-sm-center mb-0 px-2"><span
      class="float-md-left d-block d-md-inline-block">Copyright &copy; 2024 <a [routerLink]=""
        class="text-bold-800 grey darken-2" href="https://evi-e.nl"
        target="_blank">EVI-E </a></span><span *ngIf="!hideMadeWithLove"
      class="float-md-right d-block d-md-inline-block d-none d-lg-block">Hand-crafted & Made with <i
        class="feather ft-heart pink"></i>
      <span id="scroll-top"></span></span></p>
</footer>

<footer id="footer" class="footer fixed-bottom footer-dark navbar-border navbar-shadow" *ngIf="darkFooter">
  <p class="clearfix blue-grey lighten-2 text-sm-center mb-0 px-2"><span
      class="float-md-left d-block d-md-inline-block">Copyright &copy; 2024 <a [routerLink]=""
        class="text-bold-800 grey darken-2" href="https://evi-e.nl"
        target="_blank">EVI-E </a></span><span *ngIf="!hideMadeWithLove"
      class="float-md-right d-block d-md-inline-block d-none d-lg-block">Hand-crafted & Made with <i
        class="feather ft-heart pink"></i>
      <span id="scroll-top"></span></span></p>
</footer>
