<div class="app-content content">
  <div class="content-wrapper">
    <div class="content-header row ">
    </div>
    <div class="content-body">
      <section class="row flexbox-container">
        <div class="col-12 d-flex align-items-center justify-content-center">
          <div class="col-lg-4 col-md-8 col-10 box-shadow-2 p-0">
            <div class="card border-grey border-lighten-3 px-2 py-2 m-0">
              <div class="card-header border-0 pb-0">
                <div class="card-title text-center">
                  <img src="../../../../../assets/images/logo/logo-dark.png" alt="branding logo">
                </div>
                <h6 class="card-subtitle line-on-side text-muted text-center font-small-3 pt-2"><span>We sturen je een link om je wachtwoord te resetten.</span></h6>
              </div>
              <div class="card-content">
                <div class="card-body">
                  <form class="form-horizontal" [formGroup]="resetForm">
                    <fieldset class="form-group position-relative has-icon-left">
                      <input type="email" placeholder="Je E-mailadress" class="form-control" id="emailfield" type="text" formControlName="emailfield" required>
                      <div class="form-control-position">
                        <i class="feather ft-mail"></i>
                      </div>
                    </fieldset>
                    <button (click)="onClick()" class="btn btn-outline-info btn-lg btn-block"><i class="feather ft-unlock"></i>
                      Herstel Wachtwoord</button>
                  </form>
                </div>
              </div>
              <div class="card-footer border-0">
                <p class="float-sm-left text-center"><a [routerLink]="['/login']"
                    class="card-link">Inloggen</a></p>
                <p class="float-sm-right text-center">Nieuw ? <a
                    [routerLink]="['/register']" class="card-link">Maak een Account</a></p>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  </div>
</div>
