import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';


@Injectable()
export class AuthService {
  constructor(public afAuth: AngularFireAuth) { }

  // Facebook login
  doFacebookLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.FacebookAuthProvider();
      this.afAuth.signInWithPopup(provider).then(
        res => {
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  // Github login
  doGitHubLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.GithubAuthProvider();
      this.afAuth.signInWithPopup(provider).then(
        res => {
          resolve(res);
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  // Twitter login
  doTwitterLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.TwitterAuthProvider();
      this.afAuth.signInWithPopup(provider).then(
        res => {
          resolve(res);
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  // Google login
  doGoogleLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      this.afAuth.signInWithPopup(provider).then(
        res => {
          resolve(res);
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  // Register
  // doRegister(value) {
  //   return new Promise<any>((resolve, reject) => {
  //     firebase
  //       .auth()
  //       .createUserWithEmailAndPassword(value.email, value.password)
  //       .then(
  //         res => {
  //           resolve(res);
  //         },
  //         err => reject(err)
  //       );
  //   });
  // }
  doRegister(value) {
    return new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(value.email, value.password)
        .then(
          userCredential => {
            const user = userCredential.user;
  
            // Set display name from the provided namefield in the form
            user.updateProfile({
              displayName: value.firstName
            })
            .then(() => {
              // Display name set successfully
              resolve(userCredential);
            })
            .catch(err => {
              // Error setting display name
              reject(err);
            });
          },
          err => reject(err)
        );
    });
  }


  // Login
  doLogin(value) {
    return new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(value.email, value.password)
        .then(
          res => {
            console.log(res);
            resolve(res);
          },
          err => {
            console.log(err);
            reject(err)
          }
        );
    });
  }

  // Logout
  doLogout() {
    return new Promise<void>((resolve, reject) => {
      if (firebase.auth().currentUser) {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('remember');
        this.afAuth.signOut();
        resolve();
      } else {
        localStorage.removeItem('currentUser');
        resolve();
      }
    });
  }

  sendPasswordResetEmail(email: string) {
    return new Promise<void>((resolve, reject) => {
      this.afAuth.sendPasswordResetEmail(email)
        .then(
          res => {
            //console.log(res);
            resolve(res);
          },
          err => {
            console.log(err);
            reject(err)
          }
        );
    });
  }
}
